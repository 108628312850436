'use client'

import useSaveListing from '@hooks/useSaveListing'
import useUserState from '@hooks/useUserState'
import IconLoading from '@icon/const/loading.svg'
import IconFavourite from '@icon/house/favourite.svg'
import { favouriteDataLayer } from '@plugin/google/gtm/modules/property'
import { getHouseId } from '@utils/house'
import classNames from 'classnames'
import { includes, isFunction } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { useBoolean } from 'react-use'

export default function FavouriteButton({
  data,
  signupSource,
  onClick,
  id
}) {

  const { saved, clickHandle, loading } = useFavourite({ data, signupSource, onClick })
  return (
    <button className={classNames('flex-shrink-0 rounded-4xl pointer-events-auto h-32 text-sm sm:text-base sm:h-40 sm:w-158', saved ? 'button-primary' : 'button-primary-out')} data-noprogress onClick={clickHandle} id={id}>
      {
        loading
          ? 'loading...'
          : (
            <>
              {saved ? 'Listing saved' : 'Save listing'}
              <IconFavourite className={classNames('stroke-primary w-16 h-16 ml-8 pointer-events-none', saved ? 'fill-white' : 'fill-none')} />
            </>
          )
      }
    </button>
  )
}

export function Favourite({
  data,
  signupSource,
}) {

  const { saved, clickHandle, loading } = useFavourite({ data, signupSource, onClick: favouriteDataLayer.iconClick, showFolder: false })
  return (
    <FavouriteIcon saved={saved} onClick={clickHandle} loading={loading} />
  )
}

export function FavouriteIcon({
  loading,
  saved,
  onClick
}) {
  return (
    <div className={classNames('flex-shrink-0 pointer-events-auto w-30 h-30 rounded-half inline-flex items-center justify-center cursor-pointer hover:bg-primary-light', saved ? 'bg-primary-light' : 'bg-white')} data-noprogress onClick={onClick} >
      {
        loading
          ? <IconLoading className='w-1/2 h-1/2'></IconLoading>
          : <IconFavourite className={classNames('stroke-primary w-14 h-14 pointer-events-none', saved ? 'fill-primary' : 'fill-none')} />
      }
    </div >
  )
}

export function FavouriteHeart({
  data,
  signupSource,
  onClick
}) {

  const { saved, clickHandle, loading } = useFavourite({ data, signupSource, onClick })
  return (
    <button type='button' onClick={clickHandle} className='flex-shrink-0 inline-flex items-center pointer-events-auto'>
      <IconFavourite className={classNames('stroke-primary w-22 h-22', saved ? 'fill-primary' : 'fill-none')} />
      <span className='text-sm text-primary ml-8'>
        {
          loading
            ? 'loading...'
            : saved ? 'Saved' : 'Save'
        }
      </span>
    </button>
  )
}

function useFavourite({ data, signupSource, onClick, showFolder }) {

  // 获取当前用户的收藏的房源ids
  const { savedHouseIds } = useUserState()
  const { showSaveLising, saving, houseId: currentHouseId } = useSaveListing()
  // 房源id
  const houseId = getHouseId(data)
  const loading = useMemo(() => saving && (houseId === currentHouseId), [houseId, saving, currentHouseId])
  // 是否已收藏
  const [saved, setSaved] = useBoolean(false)
  useEffect(() => {
    setSaved(includes(savedHouseIds, houseId))
  }, [savedHouseIds, houseId, setSaved])

  // 点击事件
  const clickHandle = useCallback(event => {
    isFunction(onClick) && onClick(event)
    favouriteDataLayer.click(event.target.id)
    showSaveLising({ house: data, signupSource, showFolder })(event)

  }, [onClick, data, signupSource, showSaveLising, showFolder])

  return {
    saved,
    loading,
    data,
    clickHandle
  }
}